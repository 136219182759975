(function () {
  'use strict';

  class EditResourceCtrl {
    constructor(Resources, $stateParams, $mdToast, $document, $scope, Media, Utils, Discussions, Polls) {
      let vm = this,
          scale_factor = null,
          newWidth = null,
          newHeight = null,
          x = null,
          y = null;
      vm.img = new Image();
      vm.img.crossOrigin = 'anonymous';
      vm.ctrlName = 'EditResourceCtrl';
      vm.Resources = Resources;
      vm.Discussions = Discussions;
      vm.Polls = Polls;
      vm.Media = Media;
      vm.Utils = Utils;
      vm.newCanvas = false;
      vm.$stateParams = $stateParams;
      vm.$document = $document;
      vm.$mdToast = $mdToast;
      vm.types = Utils.resourceTypes();
      vm.canvas = vm.$document[0].getElementById('editcanvas');
      vm.ctx = vm.canvas.getContext('2d');
      vm.img.onload = function () {
        vm.clear();
        // let w = vm.canvas.width;
        // let nw = vm.img.naturalWidth;
        // let nh = vm.img.naturalHeight;
        // let aspect = nw / nh;
        // let h = w / aspect;
        // console.log('height', h);
        scale_factor = Math.min(vm.canvas.width / vm.img.width, vm.canvas.height / vm.img.height);
        newWidth = vm.img.width * scale_factor;
        newHeight = vm.img.height * scale_factor;
        x = vm.canvas.width / 2 - newWidth / 2;
        y = vm.canvas.height / 2 - newHeight / 2;
        vm.ctx.drawImage(vm.img, x, y, newWidth, newHeight);
        // vm.ctx.drawImage(vm.img, 0, 0, w, vm.canvas.height);
        // vm.Utils.drawImageProp(vm.ctx, vm.img);
      };
      $scope.$watch('editResource.mediaCanvas', () => {
        if (vm.mediaCanvas.file) {
          vm.newCanvas = true;
          vm.mediaCanvas.file.convertToBase64((data) => {
            vm.img.src = data;
            $scope.$apply();
          });
        }
      });
      vm.init();
    }
    init() {
      const vm = this;
      vm.Resources.resourceView(vm.$stateParams)
        .then((data)=>{
          vm.resource = data.resource;
          vm.resourceTypeSetup();
        });
    }
    submitResource(form) {
      const vm = this;
      vm.isLoading = true;
      if (form.$valid) {
        vm.resourceEdit();
      } else {
        vm.isLoading = false;
        vm.message('Please make sure that the required fields are filled');
      }
    }
    resourceEdit() {
      const vm = this;
      if (vm.resource.resource_type === 'canvas') {
        if (vm.newCanvas === true) {
          return vm.canvasResourceEdit();
        }
        return vm.updateResourceCanvas();
      }
      vm.Resources.resourceEdit(vm.resource)
        .then(() => {
          vm.afterEdit();
        });
    }
    afterEdit() {
      const vm = this;
      switch (vm.resource.resource_type) {
        case 'poll':
          vm.pollResourceEdit();
          break;
        default:
          vm.isLoading = false;
          vm.message();
          return;
      }
    }
    pollResourceEdit(counter = 0) {
      const vm = this,
          resource = vm.resource.polls[counter];
      if (resource) {
        vm.Polls.pollsUpdate(resource)
          .then(() => {
            counter++;
            vm.pollResourceEdit(counter);
          });
      } else {
        vm.isLoading = false;
        vm.message();
      }
    }
    uploadMedia(file) {
      const vm = this;
      return vm.Media.mediaCreate(file)
        .then((data) => {
          return data.media;
        });
    }
    canvasResourceEdit() {
      const vm = this,
          canvas = vm.Utils.dataURItoBlob(vm.canvas.toDataURL('image/png'));
      vm.uploadMedia(canvas)
        .then((media) => {
          vm.updateResourceCanvas(media);
        });
    }
    updateResourceCanvas(media) {
      const vm = this;
      if (media) {
        vm.resource.media_id = media.id;
      }
      if (vm.mediaLarge.element.value) {
        vm.uploadMedia(vm.mediaLarge.file)
          .then((mediaLarge) => {
            vm.resource.large_canvas_image_id = mediaLarge.id;
            vm.Resources.resourceEdit(vm.resource)
              .then(() => {
                vm.isLoading = false;
                vm.message();
              });
          });
      } else {
        vm.Resources.resourceEdit(vm.resource)
          .then(() => {
            vm.isLoading = false;
            vm.message();
          });
      }
    }
    resourceTypeSetup() {
      const vm = this;
      switch (vm.resource.resource_type) {
        case 'canvas':
          vm.canvasSetup();
          break;
        default:
          return;
      }
    }
    canvasSetup() {
      const vm = this;
      vm.img.src = vm.resource.media.url;
    }
    createDiscussion(discussionPrompt) {
      discussionPrompt.push({title: ''});
    }
    addAnswer(poll_question_options) {
      poll_question_options.push({text: ''});
    }
    removeAnswer(poll_question_options, index) {
      if (poll_question_options.length > 1) {
        poll_question_options.splice(index, 1);
      }
    }
    clear() {
      let vm = this;
      vm.ctx.clearRect(0, 0, 800, 600);
      vm.mediaCanvas.element.value = '';
      vm.mediaLarge.element.value = '';
    }
    removeDiscussion(arr, item, index) {
      const vm = this;
      if (item.id) {
        vm.Discussions.discussionDelete(item)
          .then(() => {
            arr.splice(index, 1);
          });
      } else {
        arr.splice(index, 1);
      }
    }
    message(msg = 'Resource Updated Successfully') {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
  }

  /**
   * @ngdoc object
   * @name learning.editResource.controller:EditResourceCtrl
   *
   * @description
   *
   */
  angular
    .module('learning.editResource')
    .controller('EditResourceCtrl', EditResourceCtrl);
}());
